// import axios from 'axios'

const tableTemplate = {
  // eslint-disable-next-line no-unused-vars
  rowClick(row, event, column) {
    console.log("rowClick in ...");
  },
  // eslint-disable-next-line no-unused-vars
  handleSelectChange(val) {
    //點擊單選
  },
  // eslint-disable-next-line no-unused-vars
  checkSelectable(row, index) {
    //禁用選取
    if (row.checkBoxStatus == null || row.checkBoxStatus == '' || row.checkBoxStatus == false) {
      return 1;
    } else {
      return 0;
    }
  },
}
export default tableTemplate;
